import React, { useState, useEffect } from 'react';
import styles from './ProfileInstructor.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
import ChangePasswordModal from '../../Components/ChangePassword/ChangePasswordModal';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

function ProfileInstructor() {
    const [instructorData, setInstructorData] = useState(null);
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false); // For password modal
    const [showModal, setShowModal] = useState(false);
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    const token = getToken();
    const [titleError, setTitleError] = useState("");
    const [dateError, setDateError] = useState("");
    const [startTimeError, setStartTimeError] = useState("");
    const [endTimeError, setEndTimeError] = useState("");
    const [urlError, setUrlError] = useState(""); // Track URL error

    const [meetingDetails, setMeetingDetails] = useState({
        title: "",
        date: "",
        startTime: "",
        endTime: "",
        meetingUrl: "",
    });
    const [showPopover, setShowPopover] = useState(false); // State to handle popover visibility
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMeetingDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };
    const openModal = (instructorData) => {
        setSelectedInstructor(instructorData);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setMeetingDetails({
            title: "",
            date: "",
            startTime: "",
            endTime: "",
            meetingUrl: "",
        });
    };

    useEffect(() => {
        const token = getToken();
        fetchInstructorData(token);
    }, []);

    const fetchInstructorData = (token) => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('X-CSRFToken', 'xmhB9v3mimkr2b6h9gijvOYTeo2fsmKKmzPMOPCEkGG1Y31qNZRUvjP20zd8b2qR');
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(`${API_URL}/accounts/profile/`, requestOptions)
            .then((response) => response.json())
            .then((result) => setInstructorData(result))
            .catch((error) => console.error(error));
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Reset all previous errors
        setTitleError("");
        setDateError("");
        setStartTimeError("");
        setEndTimeError("");
        setUrlError("");

        let hasError = false;

        // Validate the title
        if (!meetingDetails.title) {
            setTitleError("Title is required.");
            hasError = true;
        }

        // Validate the date
        if (!meetingDetails.date) {
            setDateError("Date is required.");
            hasError = true;
        }

        // Validate the start time
        if (!meetingDetails.startTime) {
            setStartTimeError("Start time is required.");
            hasError = true;
        }

        // Validate the end time
        if (!meetingDetails.endTime) {
            setEndTimeError("End time is required.");
            hasError = true;
        } else {
            // Ensure end time is after the start time
            const selectedStartTime = meetingDetails.startTime.split(":");
            const selectedEndTime = meetingDetails.endTime.split(":");

            if (
                parseInt(selectedEndTime[0]) < parseInt(selectedStartTime[0]) ||
                (parseInt(selectedEndTime[0]) === parseInt(selectedStartTime[0]) &&
                    parseInt(selectedEndTime[1]) <= parseInt(selectedStartTime[1]))
            ) {
                setEndTimeError("End time must be after the start time.");
                hasError = true;
            }
        }

        // Validate the meeting URL
        if (!meetingDetails.meetingUrl) {
            setUrlError("Meeting URL is required.");
            hasError = true;
        } else if (!meetingDetails.meetingUrl.startsWith("https://")) {
            setUrlError("The meeting URL must start with 'https://'.");
            hasError = true;
        }

        // If there are any errors, do not submit the form
        if (hasError) {
            return;
        }

        // Proceed with submitting the form if all validations pass
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "X-CSRFToken",
            "PQAfaQNM4rdxy6hBx8unyJEfyxnHBblavA7LjsX5kFv7aQnFF0yi5SfKXDDFtzvV"
        );

        const raw = JSON.stringify({
            instructor: selectedInstructor.id,
            title: meetingDetails.title,
            date: meetingDetails.date,
            start_time: meetingDetails.startTime,
            end_time: meetingDetails.endTime,
            meeting_url: meetingDetails.meetingUrl,
            is_reserved: false,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            // eslint-disable-next-line
            const response = await fetch(`${API_URL}/meetings/`, requestOptions);
            closeModal();

            setShowPopover(true); // Show popover on success
            setTimeout(() => {
                setShowPopover(false);
            }, 3000);
        } catch (error) {
            console.error("Error creating meeting:", error);
        }
    };



    return (
        <>
            <h2 className={styles.title}>Coach Profile</h2>
            <div className={styles.profileContainer}>
                {instructorData ? (
                    <div className={styles.userInfo}>
                        <div className={styles.userCard}>
                            <p><strong>Email:</strong> {instructorData?.user?.email || 'N/A'}</p>
                        </div>
                        <div className={styles.userCard}>
                            <p><strong>Coach ID:</strong> {instructorData?.id || 'N/A'}</p>
                        </div>
                        <div className={styles.userCard}>
                            <p><strong>Name:</strong> {instructorData?.name || 'N/A'}</p>
                        </div>
                        <div className={styles.userCard}>
                            <p><strong>Phone:</strong> {instructorData?.phone || 'N/A'}</p>
                        </div>

                        <div className={styles.coachingRoundsCard}>
                            <p><strong>Coaching Rounds:</strong></p>
                            <ul className={styles.coachingRoundsList}>
                                {instructorData?.coaching_rounds?.length > 0 ? (
                                    instructorData.coaching_rounds.map((round, index) => (
                                        <li key={index} className={styles.coachingRoundItem}>
                                            {round.code}
                                        </li>
                                    ))
                                ) : (
                                    <li>No coaching rounds available.</li>
                                )}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <p>Loading instructor data...</p>
                )}

                <button
                    className={styles.changePasswordButton}
                    onClick={() => setPasswordModalOpen(true)}
                >
                    Change Password
                </button>

                <ChangePasswordModal
                    isOpen={isPasswordModalOpen}
                    onClose={() => setPasswordModalOpen(false)}
                    token={getToken()}
                />

                <button
                    className={styles.createMeetingBtn}
                    onClick={() => openModal(instructorData)}
                >
                    Create Coaching Session
                </button>

            </div>

            {/* Updated MUI Dialog */}
            <Dialog open={showModal} onClose={closeModal} fullWidth maxWidth="sm">
                <DialogTitle>Create Coaching Session</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            label="Title"
                            name="title"
                            value={meetingDetails.title}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                            error={!!titleError}
                            helperText={titleError}
                        />

                        <TextField
                            label="Date"
                            type="date"
                            name="date"
                            value={meetingDetails.date}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ min: new Date().toISOString().split("T")[0] }}
                            required
                            error={!!dateError}
                            helperText={dateError}
                        />

                        <TextField
                            label="Start Time"
                            type="time"
                            name="startTime"
                            value={meetingDetails.startTime}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            error={!!startTimeError}
                            helperText={startTimeError}
                        />

                        <TextField
                            label="End Time"
                            type="time"
                            name="endTime"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={meetingDetails.endTime}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                            error={!!endTimeError}
                            helperText={endTimeError}
                        />

                        <TextField
                            label="Meeting URL"
                            name="meetingUrl"
                            value={meetingDetails.meetingUrl}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                            error={!!urlError} // Apply error style if there is an error
                            helperText={urlError} // Display the error message
                        />


                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button onClick={handleFormSubmit} color="primary" variant="contained">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {showPopover && (
                <div className={styles.popover}>
                    Coaching session created successfully!
                </div>
            )}
        </>
    );
}

export default ProfileInstructor;
