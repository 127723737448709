import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import styles from "./ControlPanel.module.css";
import AddMeeting from "../AddMeeting/AddMeeting";
import WebinarAttendance from "../WebinarAttendance/WebinarAttendance";
import AddWebinar from "../AddWebinar/AddWebinar";
import UploadStudents from "../UploadStudents/UploadStudents";
import WebinarAttendanceInfo from "../WebinarAttendanceInfo/WebinarAttendanceInfo";
import CreateTask from "../CreateTask/CreateTask";
import Freelance from "../../Components/Freelance/Freelance";
import EditInstructor from "../../Components/EditInstructor/EditInstructor";
import EditStudent from "../../Components/EditStudent/EditStudent";

function ControlPanel() {
  return (
    <div className={styles.dashboard}>
      <aside className={styles.sidebar}>
        <ul>
          <NavLink
            to="/control-panel/upload-students"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {" "}
            <li>Students</li>
          </NavLink>
          <NavLink
            to="/control-panel/add-webinar"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li> Webinars</li>
          </NavLink>
          {/* <NavLink to="/control-panel/webinar-attendance" className={({ isActive }) => isActive ? styles.activeLink : ''}> <li>Webinar Attendance</li></NavLink> */}
          <NavLink
            to="/control-panel/add-meeting"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {" "}
            <li> Coaching Sessions</li>
          </NavLink>
          <NavLink
            to="/control-panel/task"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {" "}
            <li> Tasks</li>
          </NavLink>
          <NavLink
            to="/control-panel/freelance"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {" "}
            <li> Freelance</li>
          </NavLink>
          <NavLink
            to="/control-panel/edit-instructor-data"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {" "}
            <li> Edit Coach</li>
          </NavLink>

          <NavLink
            to="/control-panel/edit-student-data"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {" "}
            <li> Edit Student</li>
          </NavLink>
        </ul>
      </aside>
      <main className={styles.mainContent}>
        <Routes>
          <Route path="/" element={<UploadStudents />} />

          <Route path="/upload-students" element={<UploadStudents />} />
          <Route path="/add-webinar" element={<AddWebinar />} />
          <Route
            path="/webinar-attendance/:id"
            element={<WebinarAttendance />}
          />
          <Route
            path="/webinar-attendance/info/:id"
            element={<WebinarAttendanceInfo />}
          />
          <Route path="/task" element={<CreateTask />} />
          <Route path="/freelance" element={<Freelance />} />
          <Route path="/add-meeting" element={<AddMeeting />} />
          <Route path="/edit-instructor-data" element={<EditInstructor />} />
          <Route path="/edit-student-data" element={<EditStudent />} />
        </Routes>
      </main>
    </div>
  );
}

export default ControlPanel;
