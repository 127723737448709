import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, getToken } from '../../Helpers/constants';
// import FreelanceProjects from './FreelanceProjects';
// import ChartsOverviewDemo from './MeetingBars';
import styles from './StudentDashboardPreview.module.css';
// import TaskBar from './TasksBar';
// import WebinarBar from './WebinarBars';

function StudentDashboardPreview() {
  const [students, setStudents] = useState([]);
  const [ordering, setOrdering] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [coachingRound, setCoachingRound] = useState('');
  const [jobProfileRound, setJobProfileRound] = useState('');
  const [coachingRounds, setCoachingRounds] = useState([]); // State for fetched coaching rounds
  const [jobProfileRounds, setJobProfileRounds] = useState([]); // State for fetched job profile rounds
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term

  const token = getToken();

  // Fetch students from API based on the current filters
  useEffect(() => {
    const fetchStudents = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('X-CSRFToken', 'OBJVlAMLGyJoXtAmdUA4gUELkTPDB1gVzo6ovhFKBfN8A8QyuSEz7Qw5wUYKVLXW');

      const url = new URL(`${API_URL}/admin/students/`);
      if (coachingRound) {
        url.searchParams.append('coaching_rounds__code', coachingRound);
      }
      if (jobProfileRound) {
        url.searchParams.append('job_profile_round', jobProfileRound);
      }
      if (searchTerm) url.searchParams.append('search', searchTerm); // Add search term if available

      url.searchParams.append('ordering', ordering);
      url.searchParams.append('page', page);
      url.searchParams.append('page_size', pageSize);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        // console.log('API Response:', data); // Log the response to check its structure
        if (data?.results) {
          setStudents(data.results);
          setTotalCount(data.count);
        } else {
          setStudents([]);
        }
      } catch (error) {
        console.error('Error fetching students:', error);
        setStudents([]);
      }
    };

    fetchStudents();
  }, [coachingRound, jobProfileRound, searchTerm, ordering, page, pageSize, token]);

  // Fetch coaching rounds data from API
  useEffect(() => {
    const fetchCoachingRounds = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('X-CSRFToken', 'OBJVlAMLGyJoXtAmdUA4gUELkTPDB1gVzo6ovhFKBfN8A8QyuSEz7Qw5wUYKVLXW');

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_URL}/coaching-rounds/`, requestOptions);
        const data = await response.json();
        setCoachingRounds(data); // Save the fetched data to state
      } catch (error) {
        console.error('Error fetching coaching rounds:', error);
      }
    };

    fetchCoachingRounds();
  }, [token]);

  // Fetch job profile rounds data from API
  useEffect(() => {
    const fetchJobProfileRounds = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('X-CSRFToken', 'OBJVlAMLGyJoXtAmdUA4gUELkTPDB1gVzo6ovhFKBfN8A8QyuSEz7Qw5wUYKVLXW');

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_URL}/job-profile-rounds/`, requestOptions);
        const data = await response.json();
        setJobProfileRounds(data); // Save the fetched data to state
      } catch (error) {
        console.error('Error fetching job profile rounds:', error);
      }
    };

    fetchJobProfileRounds();
  }, [token]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update search term as user types
    setPage(1); // Reset to first page for new search
  };

  // Handle removing filters
  const handleRemoveFilters = () => {
    setCoachingRound(''); // Reset coaching round
    setJobProfileRound(''); // Reset job profile round
    setOrdering(''); // Reset ordering
    setPage(1); // Reset to first page
    setSearchTerm(''); // Clear search term

  };

  const handleCoachingRoundChange = (event) => {
    setCoachingRound(event.target.value); // Update coaching round filter
    setPage(1); // Reset to first page
  };

  const handleJobProfileRoundChange = (event) => {
    setJobProfileRound(event.target.value); // Update job profile round filter
    setPage(1); // Reset to first page
  };

  const handleOrderingChange = (event) => {
    setOrdering(event.target.value); // Update ordering filter
    setPage(1); // Reset to first page
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    const pages = [];

    // Always show the first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={page === 1 ? styles.active : ''}
      >
        1
      </button>
    );

    // Show the dots if the current page is greater than 4
    if (page > 4) {
      pages.push(<span key="start-dots">.....</span>);
    }

    // Calculate start and end pages for the middle section
    const startPage = Math.max(2, page - 2);
    const endPage = Math.min(totalPages - 1, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={page === i ? styles.active : ''}
        >
          {i}
        </button>
      );
    }

    // Show the dots if we're not near the end
    if (page < totalPages - 3) {
      pages.push(<span key="end-dots">...</span>);
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={page === totalPages ? styles.active : ''}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.chart}>
        <ChartsOverviewDemo />
        <TaskBar />
        <FreelanceProjects />
        <WebinarBar />
      </div> */}
      <div className={styles.divTextField}>
        <p>
          Search By Student Email
        </p>
        <TextField
          placeholder="Search by Student Email"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchInput} // Optional styling
          // fullWidth

          margin="normal"
        />

      </div>


      <div className={styles.tableAndFilters}>
        <div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Coaching Round</th>
                <th>Job Profile Round</th>
                <th>Coach</th>
                <th>Freelance Jobs</th>
                <th>Webinar</th>
                <th>Meeting</th>
                <th>Tasks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {students?.map((student) => (
                <tr key={student.id}>
                  <td>
                    <Link to={`/student-profile/${student.id}`}>{student.id}</Link>
                  </td>
                  <td>{student.full_name}</td>
                  <td>{student.coaching_round}</td>
                  <td>{student.job_profile_round}</td>
                  <td>{student.instructor}</td>
                  <td>{student.freelance_job_count}</td>
                  <td>{student.webinar_attendance}</td>
                  <td>{student.meeting_summary}</td>
                  <td>{student.task_summary}</td>
                  <td>
                    <Link to={`/student-profile/${student.id}`}>View Profile</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.pagination}>{renderPagination()}</div>
        </div>

        <div className={styles.filter}>
          <div className={styles.FiltersButton}>
            <Button variant="contained" color="error" onClick={handleRemoveFilters}>
              Remove <FilterListIcon />
            </Button>
            <select value={pageSize} onChange={handlePageSizeChange}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
            </select>
          </div>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Coaching Round</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">Select Coaching Round</FormLabel> */}
                <RadioGroup value={coachingRound} onChange={handleCoachingRoundChange}>
                  {coachingRounds?.map((round) => (
                    <FormControlLabel
                      key={round.code}
                      value={round.code}
                      control={<Radio />}
                      label={round.code}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Job Profile Round</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">Select Job Profile Round</FormLabel> */}
                <RadioGroup value={jobProfileRound} onChange={handleJobProfileRoundChange}>
                  {jobProfileRounds?.map((round) => (
                    <FormControlLabel
                      key={round.code}
                      value={round.code}
                      control={<Radio />}
                      label={round.code}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          {/* Order By Accordion */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Order By</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup value={ordering} onChange={handleOrderingChange}>
                  <FormControlLabel value="meeting_count" control={<Radio />} label="Meeting Count" />
                  <FormControlLabel value="no_of_tasks" control={<Radio />} label="Number of Tasks" />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
    </div>
  );
}

export default StudentDashboardPreview;
