import React, { useState, useEffect } from 'react';
import styles from './AddFreelanceJob.module.css';
import Modal from '../../Components/Modal/Modal';
import { API_URL, getToken } from '../../Helpers/constants';

export default function AddFreelanceJob({ show, onClose, onSuccess }) {
    const [newFreelanceJob, setNewFreelanceJob] = useState({
        title: '',
        description: '',
        url: '',
        price: '',
        status: 'open',
        hiring_company: '',
        category: '',
        deadline: ''
    });
    const [categories, setCategories] = useState([]);
    const [hiringCompanies, setHiringCompanies] = useState([]); // State for hiring companies
    const [selectedHiringCompany, setSelectedHiringCompany] = useState(''); // State for selected hiring company
    const [selectedCategory, setSelectedCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(true); // For collapsing the category section
    const [formError, setFormError] = useState('');
    const token = getToken();

    // Fetch existing categories
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', 'm5LJiDzFBvIuRUdCCWQE7wvWRCOCfLjy2PifrfJYRJ04tEjGKOUzEF6rgI4A79tj');

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${API_URL}/categories/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setCategories(result);
            })
            .catch((error) => console.error('Error fetching categories:', error));

        // Fetch hiring companies
        fetch(`${API_URL}/platforms/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setHiringCompanies(result); // Set hiring companies in state
            })
            .catch((error) => console.error('Error fetching hiring companies:', error));
    }, [token]);

    // Handle input changes for freelance job fields
    const handleInputChange = (e) => {
        setNewFreelanceJob({ ...newFreelanceJob, [e.target.name]: e.target.value });
        setFormError(''); // Clear previous error when input changes
    };

    // Handle hiring company selection
    const handleHiringCompanyChange = (e) => {
        setSelectedHiringCompany(e.target.value); // Set selected hiring company
    };

    // Handle category selection
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value); // Save the selected category's id
    };

    // Handle posting a new category
    const handlePostCategory = async (e) => {
        e.preventDefault();

        if (!newCategory) {
            setFormError('Please provide a category name.');
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', 'm5LJiDzFBvIuRUdCCWQE7wvWRCOCfLjy2PifrfJYRJ04tEjGKOUzEF6rgI4A79tj');

        const raw = JSON.stringify({ name: newCategory });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const response = await fetch(`${API_URL}/categories/`, requestOptions);
            const result = await response.json();
            setCategories([...categories, result]); // Add the new category to the list
            setNewCategory(''); // Clear input field
        } catch (error) {
            console.error('Error posting category:', error);
        }
    };

    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    // Handle post freelance job with validation
    const handlePostFreelanceJob = async (e) => {
        e.preventDefault();

        // URL validation to ensure it starts with https
        const urlPattern = /^https:\/\/.*/;
        if (!urlPattern.test(newFreelanceJob.url)) {
            setFormError('The job URL must start with https://');
            return;
        }

        // Ensure all required fields are filled
        if (!newFreelanceJob.title || !newFreelanceJob.url || !selectedHiringCompany || (!selectedCategory && !newCategory) || !newFreelanceJob.deadline) {
            setFormError('All fields are required.');
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', 'm5LJiDzFBvIuRUdCCWQE7wvWRCOCfLjy2PifrfJYRJ04tEjGKOUzEF6rgI4A79tj');

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                ...newFreelanceJob,
                hiring_company: selectedHiringCompany,  // Use selected company id
                category: selectedCategory || newCategory,  // Use selected category id or new category
            }),
            redirect: 'follow'
        };

        try {
            const response = await fetch(`${API_URL}/freelance-opportunities/`, requestOptions);
            const result = await response.json();
            onSuccess(result); // Callback to update the parent component with new data
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error posting freelance job:', error);
            setFormError('An error occurred while submitting the job.');
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Modal show={show} onClose={onClose}>
            <div className={styles.addFreelanceJobContainer}>
                <h2>Add Job</h2>
                <form onSubmit={handlePostFreelanceJob} className={styles.form}>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        value={newFreelanceJob.title}
                        onChange={handleInputChange}
                        placeholder="Enter job title"
                    />

                    <label htmlFor="description">Description:</label>
                    <textarea
                        name="description"
                        id="description"
                        value={newFreelanceJob.description}
                        onChange={handleInputChange}
                        placeholder="Enter job description"
                    />

                    <label htmlFor="url">URL:</label>
                    <input
                        type="text"
                        name="url"
                        id="url"
                        value={newFreelanceJob.url}
                        onChange={handleInputChange}
                        placeholder="Enter job URL"
                    />

                    <label htmlFor="price">Price:</label>
                    <input
                        type="number"
                        name="price"
                        id="price"
                        value={newFreelanceJob.price}
                        onChange={handleInputChange}
                        placeholder="Enter job price"
                    />

                    <label htmlFor="status">Status:</label>
                    <select
                        name="status"
                        id="status"
                        value={newFreelanceJob.status}
                        onChange={handleInputChange}
                    >
                        <option value="open">Open</option>
                        <option value="closed">Closed</option>
                    </select>

                    {/* Hiring Company Section */}
                    <label htmlFor="hiring_company">Hiring Company:</label>
                    <select
                        id="hiring_company"
                        value={selectedHiringCompany}
                        onChange={handleHiringCompanyChange}
                    >
                        <option value="">Select a hiring company</option>
                        {hiringCompanies.map((company) => (
                            <option key={company.id} value={company.id}>
                                {company.name}
                            </option>
                        ))}
                    </select>

                    {/* Category Section */}
                    <h3 onClick={toggleCollapse} className={styles.collapsibleHeader}>
                        <span className={styles.collapsibleTitle}>Category</span>
                        <span className={styles.toggleIcon}>
                            {isCollapsed ? 'Expand +' : 'Collapse -'}
                        </span>
                    </h3>

                    {/* Collapsible content for categories */}
                    {!isCollapsed && (
                        <>
                            <label htmlFor="category">Select Category:</label>
                            <select
                                id="category"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                            >
                                <option value="">Select a category</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>

                            <h4>Or Add New Category</h4>
                            <input
                                type="text"
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                                placeholder="Enter new category"
                            />
                            <button onClick={handlePostCategory} className={styles.addCategoryBtn}>
                                Add Category
                            </button>
                        </>
                    )}

                    <label htmlFor="deadline">Deadline:</label>
                    <input
                        type="date"
                        name="deadline"
                        id="deadline"
                        min={today}
                        value={newFreelanceJob.deadline}
                        onChange={handleInputChange}
                    />

                    {formError && <p className={styles.error}>{formError}</p>}

                    <button type="submit" className={styles.addFreelanceSubmitBtn}>
                        Add Freelance Job
                    </button>
                </form>
            </div>
        </Modal>
    );
}
