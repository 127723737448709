import React from "react";
import styles from "./Footer.module.css";
import emailIcon from "../../assets/email.svg";

export default function Footer() {
    return (
        <footer className={styles.footerContainer}>
            <div className={styles.footerContent}>
                <p className={styles.footerText}>
                    If you encounter any issues or have any questions, feel free to reach out
                    to our support team. We're here to assist you!
                </p>
                <div className={styles.emailContainer}>
                    <img src={emailIcon} alt="Email Icon" className={styles.emailIcon} />
                    <a href="mailto:depisupport@eyouthegypt.com" className={styles.emailLink}>depisupport@eyouthegypt.com</a>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <h3>© 2024 EYouth. All rights reserved.</h3>
            </div>
        </footer>
    );
}
