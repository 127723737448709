import React, { useEffect, useState } from 'react';
import styles from './TasksTracking.module.css';
import { API_URL, getToken } from '../../Helpers/constants';

export default function TasksTracking() {
    const [tasks, setTasks] = useState({ results: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // Current page
    const [pageSize, setPageSize] = useState(10); // Number of tasks per page
    const [totalCount, setTotalCount] = useState(0); // Total number of tasks
    const token = getToken();

    useEffect(() => {
        const fetchTasks = async () => {
            const myHeaders = new Headers();
            myHeaders.append("accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
            };

            try {
                // Fetch tasks with pagination (page and page size)
                const response = await fetch(`${API_URL}/admin/task-submissions/?page=${page}&page_size=${pageSize}`, requestOptions);
                const data = await response.json();

                if (data?.results) {
                    setTasks(data); // Store paginated tasks
                    setTotalCount(data.count); // Set total number of tasks
                } else {
                    setTasks({ results: [] });
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching the data:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchTasks();
    }, [page, pageSize, token]); // Refetch whenever page or page size changes

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading data: {error.message}</p>;
    }

    // Function to handle file download
    const handleFileDownload = async (fileLink) => {
        // Check if the file link is valid before proceeding
        if (!fileLink) {
            console.error("No file link provided for download.");
            return;
        }

        const filePath = fileLink.substring(fileLink.lastIndexOf('/') + 1);

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            // Construct the correct download URL (adjust according to your API)
            const downloadUrl = `${API_URL}/download/${filePath}/`;

            const response = await fetch(downloadUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`File download failed: ${response.statusText}`);
            }

            const blob = await response.blob(); // Convert the response into a blob
            const url = window.URL.createObjectURL(blob); // Create a URL for the blob
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filePath); // Set the downloaded file name
            document.body.appendChild(link);
            link.click(); // Trigger the download
            link.remove(); // Clean up the link
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // Pagination control function
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        setPage(1); // Reset to first page when page size changes
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(totalCount / pageSize);
        const pages = [];

        // Always show the first page
        pages.push(
            <button
                key={1}
                onClick={() => handlePageChange(1)}
                className={page === 1 ? styles.active : ''}
            >
                1
            </button>
        );

        // Show the dots if the current page is greater than 4
        if (page > 4) {
            pages.push(<span key="start-dots">.....</span>);
        }

        // Calculate start and end pages for the middle section
        const startPage = Math.max(2, page - 2);
        const endPage = Math.min(totalPages - 1, page + 2);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={page === i ? styles.active : ''}
                >
                    {i}
                </button>
            );
        }

        // Show the dots if we're not near the end
        if (page < totalPages - 3) {
            pages.push(<span key="end-dots">...</span>);
        }

        // Always show the last page
        if (totalPages > 1) {
            pages.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageChange(totalPages)}
                    className={page === totalPages ? styles.active : ''}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading data: {error.message}</p>;
    }

    return (
        <div>
            <div className={styles.pageSizeSelector}>
                <label><strong>Show</strong></label>
                <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value={5}>5 per page</option>
                    <option value={10}>10 per page</option>
                    <option value={20}>20 per page</option>
                </select>
            </div>
            <div className={styles.tasksTrackingContainer}>
                {tasks.results.map((task) => (
                    <div key={task.id} className={styles.taskCard}>
                        <h3>{task.task_name}</h3>
                        <p>{task.task_description}</p>
                        <button className={styles.linkButton} onClick={() => handleFileDownload(task.file)}>Download File</button>
                        <a href={task.url} target="_blank" rel="noopener noreferrer">View Link</a>
                        <p><strong>Completed On: </strong> {new Date(task.completed_on).toLocaleDateString()}</p>
                        <p><strong>Student: </strong>{task.student_name}</p>
                        <p><strong>Student ID: </strong>{task.id}</p>
                        {task.comment && task.comment.length > 0 && (
                            <div className={styles.commentSection}>
                                <h4><strong>Instructor Comments: </strong></h4>
                                {task.comment.map((com, index) => (
                                    <div key={index} className={styles.comment}>
                                        <p>{com.comment}</p>
                                        <p><strong>Degree: </strong>{com.degree}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {/* Pagination Controls */}
            <div className={styles.pagination}>
                {renderPagination()}
            </div>
        </div>
    );
}
