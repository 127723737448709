import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './StudentProfileStaffPreview.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
import upWork from "../../assets/upwork.png";
import fiverr from "../../assets/fiver.png";
import khamsat from "../../assets/khmsat.png";
import nabbesh from "../../assets/nbeesh.jpg";
import tasmeemme from "../../assets/tsmem.png"
import dayjs from 'dayjs';
function StudentProfileStaffPreview() {
  const { id } = useParams(); // Get the student ID from the URL
  const [studentData, setStudentData] = useState(null);
  // eslint-disable-next-line 
  const [error, setError] = useState(null);
  const token = getToken();

  useEffect(() => {
    fetchStudentData(id);
    // eslint-disable-next-line 
  }, [id]);

  const fetchStudentData = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "JHwYKay4lvFUVf8STFN2Q29tEtxYK0xVmpYx3LYi9cfZqFh1p3q7iITTnkWYsngh");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/students/${id}/`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch student data");
        }
        return response.json();
      })
      .then((result) => {
        setStudentData(result);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  };

  const safeArray = (arr) => Array.isArray(arr) ? arr : [];
  const platformLogos = {
    Upwork: upWork,
    Fiverr: fiverr,
    Khamsat: khamsat,
    Nabbesh: nabbesh,
    Freelancer: "https://cdn6.f-cdn.com/build/icons/fl-logo.svg",
    Ureed: "https://ureed.com/favicon.ico",
    Tasmeemme: tasmeemme,
    Bawaba: "https://www.albawaba.com/sites/default/themes/zing/images/favicon.ico", // not working
    FlexJobs: "https://www.flexjobs.com/favicon.ico",
    Mostaql: "https://mostaql.hsoubcdn.com/assets/favicons/favicon.ico", //not working
    Other: "https://via.placeholder.com/50",
  };

  // Function to handle file download
  const handleFileDownload = async (fileLink) => {
    // Check if the file link is valid before proceeding
    if (!fileLink) {
      console.error("No file link provided for download.");
      return;
    }

    // Extract the file name from the full URL
    const filePath = fileLink.substring(fileLink.lastIndexOf('/') + 1); // Extract file name, e.g., depiEN.png

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "VAKICaXl1NuaygCokdVWdn9Tq60CDI09BkheLM7Eh1MKa0Iss5ZRKwKoPcgAv6aU");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      // Correct the URL to the download API endpoint
      const downloadUrl = `${API_URL}/download/screenshot/${filePath}/`;

      const response = await fetch(downloadUrl, requestOptions);

      if (!response.ok) {
        throw new Error(`File download failed: ${response.statusText}`);
      }

      const blob = await response.blob();  // Convert the response into a blob (binary data)
      const url = window.URL.createObjectURL(blob);  // Create a URL for the blob
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath);  // Set the downloaded file name
      document.body.appendChild(link);
      link.click();  // Trigger the download
      link.remove();  // Clean up the link
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <h2 className={styles.title}>Student Profile</h2>
      <div className={styles.profileContainer}>
        {studentData ? (
          <div className={styles.studentInfo}>
            {/* Basic Info Section */}
            <div className={styles.infoCard}>
              <p><strong>ID:</strong> {studentData.id || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Full Name:</strong> {studentData.full_name || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Email:</strong> {studentData.email || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Phone:</strong> {studentData.phone || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Job Profile:</strong> {studentData.job_profile || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Coaching Round:</strong> {studentData.coaching_round || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Job Profile Round:</strong> {studentData.job_profile_round || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Coach:</strong> {studentData.instructor || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Technical Provider:</strong> {studentData.technical_provider || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Track:</strong> {studentData.track || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Training Round:</strong> {studentData.training_round || "N/A"}</p>
            </div>

            <div className={styles.infoCard}>
              <p><strong>Total Freelance Income:</strong> {studentData.total_freelance_income || 0}</p>
            </div>



            <div className={styles.freelanceProfileLinksSection}>
              <h3>Freelance Profile Links</h3>
              {safeArray(studentData.freelance_profile_links).length > 0 ? (
                <ul className={styles.linkList}>
                  {studentData.freelance_profile_links.map((platform, index) => (
                    <li key={index} className={styles.linkCard}>
                      <a href={platform.url} target="_blank" rel="noopener noreferrer">
                        <img
                          src={platformLogos[platform.platform_name] || platformLogos["Other"]}
                          alt={platform.platform_name}
                          className={styles.platformLogo}
                          title={` ${platform.platform_name} profile `}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No freelance profile links available</p>
              )}
            </div>

            {/* Tasks Section */}
            <div className={styles.tasksSection}>
              <h3>Tasks</h3>
              {safeArray(studentData.tasks).length > 0 ? (
                <ul className={styles.taskList}>
                  {safeArray(studentData.tasks).map((task) => (
                    <li key={task.id} className={styles.taskCard}>
                      <p><strong>Task Name:</strong> {task.name}</p>
                      <p><strong>Description:</strong> {task.description}</p>
                      <p><strong>Deadline:</strong> {new Date(task.deadline).toLocaleString()}</p>
                      <p><strong>Reviewed:</strong> {task.reviewed ? 'Yes' : 'No'}</p>
                      <p><strong>Degree:</strong> {task.degree}</p>
                      <p><strong>Comment:</strong> {task.comment || 'No Comment'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No tasks available</p>
              )}
            </div>

            {/* Freelance Jobs Section */}
            <div className={styles.freelanceSection}>
              <h3>Freelance Jobs</h3>
              {safeArray(studentData.freelance_jobs).length > 0 ? (
                <ul className={styles.freelanceList}>
                  {safeArray(studentData.freelance_jobs).map((job) => (
                    <li key={job.id} className={styles.freelanceCard}>
                      {/* File Download Button */}
                      {job.status_proof_screenshot ? (
                        <p>
                          <strong>File:</strong>
                          <button
                            className={styles.downloadBtn}
                            onClick={() => handleFileDownload(job.status_proof_screenshot)}
                          >
                            Download Proof
                          </button>
                        </p>
                      ) : (
                        <p><strong>No file available</strong></p>
                      )}
                      <p><strong>Job URL:</strong> <a href={job.url} target="_blank" rel="noopener noreferrer">{job.url}</a></p>
                      <p><strong>Status:</strong> {job.status}</p>
                      <p><strong>Created on :</strong> {dayjs(job.created_on).format('D-M-YYYY')}</p>
                      <p><strong>Updated on:</strong> {dayjs(job.updated_on).format('D-M-YYYY')}</p>
                      <p><strong>Price:</strong> {job.price}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No freelance jobs available</p>
              )}
            </div>

            {/* Meetings Section */}
            <div className={styles.meetingsSection}>
              <h3>Coaching Sessions</h3>
              {safeArray(studentData.meetings).length > 0 ? (
                <ul className={styles.meetingList}>
                  {safeArray(studentData.meetings).map((meeting) => (
                    <li key={meeting.id} className={styles.meetingCard}>
                      <p><strong>Coaching Session ID:</strong> {meeting.meeting}</p>
                      <p><strong>Joined:</strong> {meeting.joined ? 'Yes' : 'No'}</p>
                      <p><strong>Coach Feedback:</strong> {meeting.instructor_feedback || 'None'}</p>
                      <p><strong>Student Feedback:</strong> {meeting.student_feedback || 'None'}</p>
                      <p><strong>Student Rating:</strong> {meeting.student_rating || 'N/A'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Coaching Sessions available</p>
              )}
            </div>

            {/* Webinars Section */}
            <div className={styles.webinarsSection}>
              <h3>Webinars</h3>
              {safeArray(studentData.webinars).length > 0 ? (
                <ul className={styles.webinarList}>
                  {safeArray(studentData.webinars).map((webinar, index) => (
                    <li key={index} className={styles.webinarCard}>
                      <p><strong>Webinar Name:</strong> {webinar.name}</p>
                      <p><strong>Joined:</strong> {webinar.joined ? 'Yes' : 'No'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No webinars available</p>
              )}
            </div>
          </div>
        ) : (
          <p>Loading student data...</p>
        )}
      </div>
    </>
  );
}

export default StudentProfileStaffPreview;
