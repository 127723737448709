import React, { useState, useEffect } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './GetALLMeetings.module.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function GetALLMeetings() {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1); // To track current page
  const [totalCount, setTotalCount] = useState(0); // Total count of meetings
  const [limit] = useState(20); // Number of meetings per page
  const token = getToken();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", `CRauAZd077qDFDFrKPpFQA70HmrDRt55nExXKG6Z2OuniiVD1NtaHwZkTnAKbdM6`);

    const offset = (page - 1) * limit; // Calculate the offset based on the current page

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/meetings/?limit=${limit}&offset=${offset}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMeetings(result.results); // Set paginated meetings
        setTotalCount(result.count); // Set total meeting count for pagination
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [page, limit]);

  const openModal = (meeting) => {
    setSelectedMeeting(meeting);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedMeeting(null);
  };

  const totalPages = Math.ceil(totalCount / limit); // Calculate total number of pages

  // Function to generate pagination buttons
  const generatePaginationButtons = () => {
    let pagination = [];
    const maxVisibleButtons = 3; // Number of pages to show around the current page

    // Always show the first page
    if (page > 1) {
      pagination.push(1);
    }

    // Add ellipsis if necessary (between 1 and the first visible page)
    if (page > maxVisibleButtons + 1) {
      pagination.push("...");
    }

    // Show pages around the current page
    for (let i = Math.max(2, page - maxVisibleButtons); i <= Math.min(totalPages - 1, page + maxVisibleButtons); i++) {
      pagination.push(i);
    }

    // Add ellipsis if necessary (between the last visible page and the last page)
    if (page < totalPages - maxVisibleButtons) {
      pagination.push("...");
    }

    // Always show the last page if it's not already in the pagination
    if (page !== totalPages && totalPages > 1) {
      pagination.push(totalPages);
    }

    return pagination;
  };

  const handlePageChange = (pageNum) => {
    if (typeof pageNum === 'number') {
      setPage(pageNum);
    }
  };

  // Function to export coaching sessions as CSV
  const handleExportCSV = () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", 'fCjSJmLgZ2E48BQvjBeBP0a7XalqXjbSpSqHR6428N0sTaYcok9BYEmTC86wr9IU');

    fetch(`${API_URL}/export/instructors/csv/`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.blob()) // Get the response as a Blob (file-like object)
      .then(blob => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'coaching_sessions.csv'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error exporting CSV:', error));
  };

  return (
    <div>
      <div className={styles.exportButtonContainer}>
        {/* Export CSV Button */}
        <Button variant="contained" color="primary" onClick={handleExportCSV}>
          Export Coaching Sessions CSV
        </Button>
      </div>

      <table border="1" cellPadding="10" cellSpacing="0" className={styles.tableWidth}>
        <thead>
          <tr>
            <th>Instructor ID</th>
            <th>Student ID</th>
            <th>Title</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {meetings.map((meeting, i) => (
            <tr key={i}>
              <td>{meeting.instructor}</td>
              <td>{meeting.attendances.length > 0 ? meeting.attendances[0].student : '_'}</td>
              <td>{meeting.title}</td>
              <td>{meeting.date}</td>
              <td>{meeting.start_time}</td>
              <td>{meeting.end_time}</td>
              <td>
                <Button variant="contained" color="primary" onClick={() => openModal(meeting)}>Show Details</Button>
                {meeting.meeting_url && (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: '10px' }}
                    onClick={() => window.open(meeting.meeting_url, '_blank', 'noopener,noreferrer')}
                  >
                    Join
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className={styles.pagination}>
        {generatePaginationButtons().map((pageNum, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(pageNum)}
            className={pageNum === page ? styles.active : ''}
            disabled={pageNum === "..."}
          >
            {pageNum}
          </button>
        ))}
      </div>

      {/* Combined Attendance & Feedback Modal */}
      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md" fullWidth>
        <DialogTitle>Attendance & Feedback</DialogTitle>
        <DialogContent>
          {selectedMeeting ? (
            <div>
              <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Instructor Feedback</th>
                    <th>Student Feedback</th>
                    <th>Student Rating</th>
                    <th>Joined</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMeeting.attendances.map((attendance, index) => (
                    <tr key={index}>
                      <td>{attendance.instructor_feedback || 'No Instructor Feedback'}</td>
                      {selectedMeeting.feedbacks[index] ? (
                        <>
                          <td>{selectedMeeting.feedbacks[index].feedback || 'No Feedback'}</td>
                          <td>{selectedMeeting.feedbacks[index].rating || 'No Rating'}</td>
                        </>
                      ) : (
                        <>
                          <td>No Feedback</td>
                          <td>No Rating</td>
                        </>
                      )}
                      <td>{attendance.joined ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GetALLMeetings;
