import React, { useEffect, useState } from 'react';
import styles from './StudentDashboard.module.css';
import { API_URL, getToken } from '../../Helpers/constants';

function StudentDashboard() {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordering, setOrdering] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const token = getToken();

  useEffect(() => {
    const fetchStudents = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('X-CSRFToken', 'your-csrf-token');

      const url = new URL(`${API_URL}/admin/students/`);
      url.searchParams.append('coaching_rounds__code', searchTerm);
      url.searchParams.append('ordering', ordering);
      url.searchParams.append('page', page);
      url.searchParams.append('page_size', pageSize);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        setStudents(data.results);
        setTotalCount(data.count);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, [searchTerm, ordering, page, pageSize, token]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to the first page when search term changes
  };

  const handleOrderingChange = (e) => {
    setOrdering(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setPage(1); // Reset to the first page when page size changes
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    const paginationRange = 5; // Show first 5 pages before the ellipsis
    const pages = [];

    // Always display the first page
    if (page > 1) {
      pages.push(
        <button key={1} onClick={() => handlePageChange(1)} className={page === 1 ? styles.active : ''}>
          1
        </button>
      );
    }

    // If current page is beyond the first few pages, show ellipsis
    if (page > paginationRange) {
      pages.push(<span key="ellipsis-start">...</span>);
    }

    // Add pages around the current page (limited to paginationRange)
    for (let i = Math.max(2, page - 2); i <= Math.min(totalPages - 1, page + 2); i++) {
      pages.push(
        <button key={i} onClick={() => handlePageChange(i)} className={page === i ? styles.active : ''}>
          {i}
        </button>
      );
    }

    // If there are more pages between the current set and the last page, show ellipsis
    if (page + 2 < totalPages - 1) {
      pages.push(<span key="ellipsis-end">...</span>);
    }

    // Always display the last page
    if (page !== totalPages && totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={page === totalPages ? styles.active : ''}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <input
          type="text"
          placeholder="Search by coaching rounds"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select value={ordering} onChange={handleOrderingChange}>
          <option value="">Order by</option>
          <option value="meeting_summary">Meeting Summary</option>
          <option value="task_summary">Task Summary</option>
        </select>
        <select value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
        </select>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Coaching Round</th>
            <th>Job Profile Round</th>
            <th>Instructor</th>
            <th>Meeting Summary</th>
            <th>Webinar Attendance</th>
            <th>Freelance Job Count</th>
            <th>Task Summary</th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 ? (
            students.map((student) => (
              <tr key={student.id}>
                <td>{student.id}</td>
                <td>{student.full_name}</td>
                <td>{student.coaching_round}</td>
                <td>{student.job_profile_round}</td>
                <td>{student.instructor}</td>
                <td>{student.meeting_summary}</td>
                <td>{student.webinar_attendance}</td>
                <td>{student.freelance_job_count}</td>
                <td>{student.task_summary}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" style={{ textAlign: 'center' }}>
                No students found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className={styles.pagination}>{renderPagination()}</div>
    </div>
  );
}

export default StudentDashboard;
