import React, { useState, useEffect } from 'react';
import styles from './Freelance.module.css';
import Modal from '../../Components/Modal/Modal'; // Importing your custom Modal component
import AddFreelanceJob from "../AddFreelanceJob/AddFreelanceJob"
import { API_URL, getToken } from '../../Helpers/constants';
import FreelanceJobsTable from '../FreelanceJobsTable/FreelanceJobsTable';

export default function Freelance() {
    const [opportunities, setOpportunities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingOpportunity, setEditingOpportunity] = useState(null); // State for editing
    const [showEditModal, setShowEditModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false); // New state for CSV modal
    const [selectedFile, setSelectedFile] = useState(null); // State for file selection
    const [message, setMessage] = useState(''); // State for success/error message
    const [isSuccess, setIsSuccess] = useState(false); // State for success status
    const [formData, setFormData] = useState({}); // State to hold form data
    const [showAddModal, setShowAddModal] = useState(false); // Modal for adding freelance job
    const token = getToken();

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', '7Fj3t9D4dsKaRpdS1loPDIFA1XiB4Ou3NpQzCLNntG2Kt9jW9dsKaRg5q3yzWcEO');

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${API_URL}/freelance-opportunities/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setOpportunities(result);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setIsLoading(false);
            });
    }, [token]);

    // Handle CSV file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setMessage(''); // Clear previous messages
        setIsSuccess(false); // Reset success state
    };

    // Handle CSV upload
    const handleUploadFile = () => {
        if (!selectedFile) {
            setMessage('Please select a file before uploading.');
            setIsSuccess(false);
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', 'l4tobGgiEJnq5digY0iEelMrb6v0d2NQ1O0UkiqBUXF0HXok6SmzLunWAcLY5qXB');

        const formData = new FormData();
        formData.append('file', selectedFile);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow',
        };

        fetch(`${API_URL}/freelance-opportunities/upload/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setMessage('File uploaded successfully.');
                setIsSuccess(true);
                setTimeout(() => {
                    setShowUploadModal(false); // Close modal after success
                    setMessage('');
                }, 4000);
            })
            .catch((error) => {
                setMessage(`Error: ${error.message}`);
                setIsSuccess(false);
            });
    };

    const handleEditClick = (opportunity) => {
        setEditingOpportunity(opportunity);
        setFormData(opportunity); // Populate the form with current data
        setShowEditModal(true); // Show the modal
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSaveChanges = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', '7Fj3t9D4dsKaRpdS1loPDIFA1XiB4Ou3NpQzCLNntG2Kt9jW9dsKaRg5q3yzWcEO');

        const requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(formData),
            redirect: 'follow'
        };

        fetch(`${API_URL}/freelance-opportunities/${editingOpportunity.id}/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // Update the opportunity list with the updated opportunity
                const updatedOpportunities = opportunities.map((opp) =>
                    opp.id === result.id ? result : opp
                );
                setOpportunities(updatedOpportunities);
                setShowEditModal(false); // Close the modal
            })
            .catch((error) => console.error(error));
    };

    const handleNewFreelanceJobSuccess = (newJob) => {
        setOpportunities([...opportunities, newJob]); // Add the new job to the opportunities list
    };

    return (
        <div>
            <div className={styles.freelanceContainer}>
                <div className={styles.freelanceHeader}>
                    <h1 className={styles.freelanceHead}>Freelance Opportunities</h1>
                    <button className={styles.uploadButton} onClick={() => setShowAddModal(true)}>
                        Add Job
                    </button>
                    <button className={styles.uploadButton} onClick={() => setShowUploadModal(true)}>
                        Upload CSV
                    </button>
                </div>

                {isLoading && <p>Loading opportunities...</p>}
                {error && <p className={styles.errorMessage}>{error}</p>}

                <div className={styles.opportunityList}>
                    {opportunities.length > 0 ? (
                        opportunities.map((opportunity) => (
                            <div key={opportunity.id} className={styles.opportunityCard}>
                                <h2>{opportunity.title}</h2>
                                <p><strong>Description:</strong> {opportunity.description}</p>
                                <p><strong>Hiring Company:</strong> {opportunity.hiring_company}</p>
                                <p><strong>Price:</strong> ${opportunity.price}</p>
                                <p><strong>Status:</strong> {opportunity.status}</p>
                                <p><strong>Category:</strong> {opportunity.category}</p>
                                <p><strong>Created On:</strong> {new Date(opportunity.created_on).toLocaleDateString()}</p>
                                <p><strong>Deadline:</strong> {new Date(opportunity.deadline).toLocaleDateString()}</p>
                                <a href={opportunity.url} className={styles.viewMoreLink} target="_blank" rel="noopener noreferrer">
                                    Link
                                </a>
                                <button onClick={() => handleEditClick(opportunity)} className={styles.editButton}>
                                    Edit
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>No freelance opportunities available at the moment.</p>
                    )}
                </div>


                {/* Using your custom Modal component */}
                <Modal show={showEditModal} onClose={() => setShowEditModal(false)}>
                    <h2 className={styles.freelanceHead}>Edit Opportunity</h2>
                    <form className={styles.editForm}>
                        <label>Title</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title || ''}
                            onChange={handleInputChange}
                        />

                        <label>Description</label>
                        <textarea
                            name="description"
                            value={formData.description || ''}
                            onChange={handleInputChange}
                        />

                        <label>Price</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price || ''}
                            onChange={handleInputChange}
                        />

                        <label>Status</label>
                        <select
                            name="status"
                            value={formData.status || 'open'}
                            onChange={handleInputChange}
                        >
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                        </select>

                        <label>Deadline</label>
                        <input
                            type="date"
                            name="deadline"
                            value={formData.deadline || ''}
                            onChange={handleInputChange}
                        />
                    </form>
                    <div className={styles.btnSection}>
                        <button onClick={handleSaveChanges} className={styles.saveButton}>
                            Save Changes
                        </button>
                        <button onClick={() => setShowEditModal(false)} className={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </Modal>

                {/* Modal for uploading CSV */}
                <Modal show={showUploadModal} onClose={() => setShowUploadModal(false)}>
                    <h2 className={styles.freelanceHead}>Upload Freelance Opportunities CSV</h2>
                    <div className={styles.uploadSection}>
                        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
                        <div className={styles.btnSection}>
                            <button onClick={handleUploadFile} className={styles.uploadButton}>
                                Upload
                            </button>

                            {/* Download Template */}
                            <a href="/Freelance_templete.xlsx" download className={styles.downloadLink}>
                                Download CSV Template
                            </a>
                        </div>

                        {/* Display the message with success or error styles */}
                        {message && (
                            <p className={isSuccess ? styles.successMessage : styles.errorMessage}>
                                {message}
                            </p>
                        )}
                    </div>
                </Modal>

                {/* Modal for adding a new freelance job */}
                <AddFreelanceJob
                    show={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    onSuccess={handleNewFreelanceJobSuccess}
                />
            </div>
            <FreelanceJobsTable />
        </div>
    );
}
