import { API_URL } from "../Helpers/constants"

// Login User Api
export async function loginUser(email, password) {
    try {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", "My9xuW8Swy8Rsf3voZ1JgjFdWdbM86fVsiG3DyibMMqr4Z9zwR5ENsgIljrK0upG");

        const raw = JSON.stringify({
            email: email,
            password: password,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(`${API_URL}/accounts/login/`, requestOptions);
        const result = await res.json();

        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// Weekly Tasks API
export async function getTasks(token) {
    try {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "My9xuW8Swy8Rsf3voZ1JgjFdWdbM86fVsiG3DyibMMqr4Z9zwR5ENsgIljrK0upG");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        const res = await fetch(`${API_URL}/tasks/`, requestOptions);
        const result = await res.json();

        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// Task submit API
export async function submitTask(taskId, file, url, token) {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "My9xuW8Swy8Rsf3voZ1JgjFdWdbM86fVsiG3DyibMMqr4Z9zwR5ENsgIljrK0upG");

    const formdata = new FormData();
    formdata.append("task", taskId);
    formdata.append("file", file);
    formdata.append("url", url);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${API_URL}/task-submissions/`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error submitting task:", error);
        throw error;
    }
}
