import React, { useState, useEffect } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './FreelanceJobsTable.module.css';

export default function FreelanceJobsTable() {
    const [opportunities, setOpportunities] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15); // Display 15 items per page
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // For confirmation dialog
    const [actionType, setActionType] = useState(''); // Track the action type (approve/reject)
    const [selectedOpportunityId, setSelectedOpportunityId] = useState(null); // Track selected opportunity ID
    const token = getToken();

    // Function to fetch opportunities
    const fetchOpportunities = async () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        try {
            const response = await fetch(`${API_URL}/freelance-jobs/`, requestOptions);
            const data = await response.json();
            setOpportunities(data.results || data); // Adjust based on your API
        } catch (error) {
            console.error('Error fetching opportunities:', error);
            setError('Failed to fetch opportunities.');
        }
    };

    useEffect(() => {
        fetchOpportunities();
    }, [token]);

    // Handle pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOpportunities = opportunities.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Generate pagination numbers with ellipsis
    const getPagination = () => {
        const pageNumbers = [];
        const maxVisibleButtons = 5; // Maximum number of buttons to show before adding an ellipsis
        const totalPages = Math.ceil(opportunities.length / itemsPerPage);

        // Always show the first page
        pageNumbers.push(1);

        // If there are more than maxVisibleButtons, show ellipsis and last page
        if (totalPages > maxVisibleButtons + 1) {
            if (currentPage > maxVisibleButtons) {
                pageNumbers.push('...');
            }

            const startPage = Math.max(2, currentPage - 2);
            const endPage = Math.min(totalPages - 1, currentPage + 2);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }

            if (currentPage + 2 < totalPages - 1) {
                pageNumbers.push('...');
            }

            pageNumbers.push(totalPages);
        } else {
            // If total pages are less than or equal to maxVisibleButtons, show all pages
            for (let i = 2; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        }

        return pageNumbers;
    };

    // Function to handle file download
    const handleFileDownload = async (fileLink) => {
        if (!fileLink) {
            console.error("No file link provided for download.");
            return;
        }

        const filePath = fileLink.substring(fileLink.lastIndexOf('/') + 1); // Extract file name
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "VAKICaXl1NuaygCokdVWdn9Tq60CDI09BkheLM7Eh1MKa0Iss5ZRKwKoPcgAv6aU");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        try {
            const downloadUrl = `${API_URL}/download/screenshot/${filePath}/`;
            const response = await fetch(downloadUrl, requestOptions);

            if (!response.ok) {
                throw new Error(`File download failed: ${response.statusText}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filePath);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // Handle confirmation for approve/reject actions
    const handleActionConfirm = (id, type) => {
        setSelectedOpportunityId(id);
        setActionType(type);
        setOpenConfirmDialog(true);
    };

    const handleAction = async () => {
        const myHeaders = new Headers({
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-CSRFToken': 'HjYB73VtYFxwUvhsl3EBShnI85TTkqgoz6ULJLtTvvOihwiiXvHJSV7goXUaZSEv',
        });

        const requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
        };

        try {
            const apiUrl = `${API_URL}/freelance-jobs/${actionType === 'approve' ? 'approve' : 'reject'}/${selectedOpportunityId}/`;
            const response = await fetch(apiUrl, requestOptions);
            if (response.ok) {
                // Refresh the table by fetching updated opportunities
                await fetchOpportunities();
            } else {
                throw new Error(`Failed to ${actionType} the opportunity.`);
            }
        } catch (error) {
            console.error(`Error ${actionType === 'approve' ? 'approving' : 'rejecting'} the opportunity:`, error);
            setError(`Error ${actionType === 'approve' ? 'approving' : 'rejecting'} the opportunity.`);
        } finally {
            setOpenConfirmDialog(false); // Close the confirmation dialog
        }
    };

    return (
        <div className={styles.freelanceJobsTableContainer}>
            <h2>Freelance Job</h2>

            {error && <div className={styles.error}>{error}</div>}

            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Student</th>
                            <th>Status</th>
                            <th>Price</th>
                            <th>Created On</th>
                            <th>URL</th>
                            <th>Status Proof</th>
                            <th>Approve</th>
                            <th>Reject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentOpportunities.map((opportunity) => (
                            <tr key={opportunity.id}>
                                <td>{opportunity.id}</td>
                                <td>{opportunity.title}</td>
                                <td>{opportunity.student}</td>
                                <td>{opportunity.status}</td>
                                <td>{opportunity.price}</td>
                                <td>{new Date(opportunity.created_on).toLocaleDateString()}</td>
                                <td>
                                    <a
                                        href={opportunity.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.joinButton}
                                    >
                                        View
                                    </a>
                                </td>
                                <td>
                                    {opportunity.status_proof_screenshot ? (
                                        <button
                                            className={styles.downloadBtn}
                                            onClick={() => handleFileDownload(opportunity.status_proof_screenshot)}
                                        >
                                            Download Proof
                                        </button>
                                    ) : (
                                        <p><strong>No file available</strong></p>
                                    )}
                                </td>
                                <td>
                                    <button
                                        onClick={() => handleActionConfirm(opportunity.id, 'approve')}
                                        className={styles.approveButton}
                                    >
                                        Approve
                                    </button>
                                </td>
                                <td>
                                    <button
                                        onClick={() => handleActionConfirm(opportunity.id, 'reject')}
                                        className={styles.rejectButton}
                                    >
                                        Reject
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className={styles.pagination}>
                {getPagination().map((pageNum, index) =>
                    pageNum === '...' ? (
                        <span key={index} className={styles.ellipsis}>
                            ...
                        </span>
                    ) : (
                        <button
                            key={pageNum}
                            onClick={() => handlePageChange(pageNum)}
                            className={pageNum === currentPage ? styles.active : ''}
                        >
                            {pageNum}
                        </button>
                    )
                )}
            </div>

            {/* Confirmation Popup */}
            {openConfirmDialog && (
                <div className={styles.confirmDialogOverlay}>
                    <div className={styles.confirmDialog}>
                        <h3>Confirm {actionType === 'approve' ? 'Approval' : 'Rejection'}</h3>
                        <p>Are you sure you want to {actionType === 'approve' ? 'approve' : 'reject'} this opportunity?</p>
                        <div className={styles.confirmActions}>
                            <button onClick={handleAction} className={styles.confirmButton}>
                                Yes, {actionType === 'approve' ? 'Approve' : 'Reject'}
                            </button>
                            <button
                                onClick={() => setOpenConfirmDialog(false)}
                                className={styles.cancelButton}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
