import React from 'react';
import styles from "./Unauthorized.module.css" 

function Unauthorized() {
    return (
        <div className={styles.unauthorizedContainer}>
            <h2>Unauthorized Access</h2>
            <p>You do not have permission to view this page.</p>
        </div>
    );
}

export default Unauthorized;
