import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Snackbar,
    Alert,
} from '@mui/material';
import { API_URL, getToken } from '../../Helpers/constants';
import coach from "../../assets/Coach.svg";
import dateIcon from "../../assets/date.svg";
import timeIcon from "../../assets/time.svg";
import styles from './Meeting.module.css';


const MeetingPage = () => {
    const [allMeetings, setAllMeetings] = useState([]); // For "All Meetings" tab
    const [myMeetings, setMyMeetings] = useState([]); // For "My Meetings" tab
    const [finishedMeetings, setFinishedMeetings] = useState([]); // For "Finished Meetings" tab
    const [tabValue, setTabValue] = useState(0); // Tracks the active tab
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false); // Feedback popup state
    const [feedback, setFeedback] = useState(''); // Feedback input state
    const [rating, setRating] = useState(''); // Rating input state
    const [selectedMeetingId, setSelectedMeetingId] = useState(null); // Selected meeting for feedback
    const [errorMessage, setErrorMessage] = useState(''); // API error message
    const [successMessage, setSuccessMessage] = useState(''); // API success message
    const [reserveError, setReserveError] = useState(''); // State to hold the error message
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false); // Snackbar for error
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false); // Snackbar for success

    const token = getToken();

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorSnackbar(false);
        setOpenSuccessSnackbar(false);
    };

    // Fetch data on mount and when tab changes
    useEffect(() => {
        if (tabValue === 0) {
            fetchAllMeetings();
        } else if (tabValue === 1) {
            fetchMyMeetings();
        } else if (tabValue === 2) {
            fetchFinishedMeetings();
        }
        // eslint-disable-next-line
    }, [tabValue, token]);

    // Fetch "All Meetings"
    const fetchAllMeetings = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', `YourCSRFTokenHere`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(`${API_URL}/meetings/`, requestOptions)
            .then((response) => response.json())
            .then((result) => setAllMeetings(result))
            .catch((error) => {
                setErrorMessage('Failed to fetch all meetings.');
                setOpenErrorSnackbar(true);
                console.error(error);
            });
    };

    // Fetch "My Meetings"
    const fetchMyMeetings = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', `YourCSRFTokenHere`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(`${API_URL}/meetings/my_reserved_meetings/`, requestOptions)
            .then((response) => response.json())
            .then((result) => setMyMeetings(result))
            .catch((error) => {
                setErrorMessage('Failed to fetch your meetings.');
                setOpenErrorSnackbar(true);
                console.error(error);
            });
    };

    // Fetch "Finished Meetings"
    const fetchFinishedMeetings = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', 'YourCSRFTokenHere');

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(`${API_URL}/meetings/finished_meetings/`, requestOptions)
            .then((response) => response.json())
            .then((result) => setFinishedMeetings(result))
            .catch((error) => {
                setErrorMessage('Failed to fetch finished meetings.');
                setOpenErrorSnackbar(true);
                console.error(error);
            });
    };

    // Handle tab change
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleReserve = (meetingId) => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', 'YourCSRFTokenHere');

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({}), // Sending an empty body
            redirect: 'follow',
        };

        fetch(`${API_URL}/meetings/${meetingId}/reserve_slot/`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response.json().then((data) => {
                    throw new Error(data.error || 'Failed to reserve the meeting.');
                });
            })
            .then((result) => {
                // Update the state with the latest data
                setAllMeetings((prevMeetings) =>
                    prevMeetings.map((meeting) =>
                        meeting.id === meetingId
                            ? { ...meeting, is_reserved: true, meeting_url: result.meeting_url }
                            : meeting
                    )
                );
                setSuccessMessage('Meeting reserved successfully.');
                setOpenSuccessSnackbar(true);
                setReserveError(''); // Clear any previous error
                fetchMyMeetings(); // Optionally, refetch myMeetings
            })
            .catch((error) => {
                setReserveError(error.message); // Set the error message to display
                setErrorMessage('Failed to reserve the meeting.');
                setOpenErrorSnackbar(true);

                // Set a timeout to clear the error message after 10 seconds
                setTimeout(() => {
                    setReserveError('');
                }, 5000);
            });
    };

    // Handle feedback submission
    const handleFeedbackSubmit = () => {
        // Ensure the rating is between 1 and 5
        const numericRating = Number(rating);
        if (numericRating < 1 || numericRating > 5) {
            setErrorMessage('Rating must be between 1 and 5.');
            setOpenErrorSnackbar(true);
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', 'YourCSRFTokenHere');

        const raw = JSON.stringify({
            feedback,
            rating: numericRating,
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${API_URL}/meetings/${selectedMeetingId}/feedback/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.error);
                    });
                }
                return response.json();
            })
            .then(() => {
                setSuccessMessage('Feedback sent successfully.');
                setOpenSuccessSnackbar(true);
                setErrorMessage('');
                setOpenFeedbackDialog(false);

                // Refetch data based on the active tab to update the UI
                if (tabValue === 0) {
                    fetchAllMeetings();
                } else if (tabValue === 1) {
                    fetchMyMeetings();
                } else if (tabValue === 2) {
                    fetchFinishedMeetings();
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setOpenErrorSnackbar(true);
                setSuccessMessage('');
            });
    };

    // Open feedback dialog
    const handleFeedback = (meetingId) => {
        setSelectedMeetingId(meetingId);
        setOpenFeedbackDialog(true);
    };

    // Close feedback dialog
    const handleCloseFeedbackDialog = () => {
        setOpenFeedbackDialog(false);
        setFeedback('');
        setRating('');
        setErrorMessage('');
        setSuccessMessage('');
    };

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    // Render meetings for the active tab
    const renderMeetings = (meetings, buttonLabel, buttonAction, showFeedback = false) => {
        return (
            <Box className={styles.box}>
                {meetings.map((meeting) => (
                    <Card key={meeting.id} className={styles.meetingCard}>
                        <CardContent>
                            <Typography className={styles.meetingTitle}>
                                {meeting.title}
                            </Typography>

                            <Typography className={styles.subtitle}>
                                <img src={coach} alt="Coach Icon" className={styles.icon} />
                                <strong>Coach:</strong>{' '}
                                {meeting.instructor}
                            </Typography>

                            <Typography className={styles.subtitle}>
                                <img src={dateIcon} alt="Date Icon" className={styles.icon} />
                                <strong>Date:</strong> {meeting.date}
                            </Typography>

                            <Typography className={styles.subtitle}>
                                <img src={timeIcon} alt="Time Icon" className={styles.icon} />
                                <strong>Time:</strong>{' '}
                                {formatTime(meeting.start_time)}{' '}
                                <span className={styles.timeGap}> - </span>
                                {formatTime(meeting.end_time)}
                            </Typography>

                            <div className={styles.reserveBtnContainer}>
                                {buttonLabel && (
                                    <Button
                                        className={`${styles.reserveBtn} ${meeting.is_reserved && buttonLabel === 'Reserve Now' ? styles.disabled : ''}`}
                                        variant="contained"
                                        onClick={() => buttonAction(meeting)}
                                        disabled={meeting.is_reserved && buttonLabel === 'Reserve Now'}
                                    >
                                        {meeting.is_reserved && buttonLabel === 'Reserve Now'
                                            ? 'Meeting Reserved'
                                            : buttonLabel}
                                    </Button>
                                )}
                                {showFeedback && (
                                    <Button
                                        className={styles.feedbackBtn}
                                        variant="contained"
                                        onClick={() => handleFeedback(meeting.id)}
                                    >
                                        Leave Feedback
                                    </Button>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        );
    };

    return (
        <div>
            {reserveError && <Alert severity="error" className={styles.errorMessage}>{reserveError}</Alert>}

            <div className={styles.tabsContainer}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    className={styles.infoTabs}
                    textColor="primary"
                    TabIndicatorProps={{ style: { display: 'none' } }} // Remove underline
                >
                    <Tab
                        label="All Coaching Sessions"
                        className={`${styles.navLink} ${tabValue === 0 ? styles.activeTab : ""}`}
                    />
                    <Tab
                        label="My Coaching Sessions"
                        className={`${styles.navLink} ${tabValue === 1 ? styles.activeTab : ""}`}
                    />
                    <Tab
                        label="Finished Sessions"
                        className={`${styles.navLink} ${tabValue === 2 ? styles.activeTab : ""}`}
                    />
                </Tabs>
            </div>

            {/* Show different data for each tab */}
            {tabValue === 0 && (
                allMeetings.length > 0 ? (
                    renderMeetings(allMeetings, 'Reserve Now', (meeting) => handleReserve(meeting.id))
                ) : (
                    <Typography className={styles.noMeetingsMessage}>
                        There are no meetings to show.
                    </Typography>
                )
            )}
            {tabValue === 1 && (
                myMeetings.length > 0 ? (
                    renderMeetings(
                        myMeetings,
                        'Join Coaching Session',
                        (meeting) => window.open(meeting.meeting_url),
                        true
                    )
                ) : (
                    <Typography className={styles.noMeetingsMessage}>
                        There are no meetings to show.
                    </Typography>
                )
            )}
            {tabValue === 2 && (
                finishedMeetings.length > 0 ? (
                    renderMeetings(finishedMeetings)
                ) : (
                    <Typography className={styles.noMeetingsMessage}>
                        There are no finished sessions available.
                    </Typography>
                )
            )}

            {/* Feedback Dialog */}
            <Dialog open={openFeedbackDialog} onClose={handleCloseFeedbackDialog}>
                <DialogTitle>Leave Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide your feedback and rate the Coaching Session.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Feedback"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Rating (1-5)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFeedbackDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleFeedbackSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {reserveError}
                </Alert>
            </Snackbar>

            <Snackbar
                open={openSuccessSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

        </div>
    );
};

export default MeetingPage;