import React, { useState } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './UploadStudents.module.css';

function UploadStudents() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);  // Track success status
  const token = getToken();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setMessage('');  // Clear messages on new file selection
    setIsSuccess(null);  // Reset success status
  };

  const uploadFile = (apiUrl) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "Grxnphd602rKgJyxPKP0CNZ3TStvCq8breUQzY65VJvuToOJ6ITvtJRn5TCCWaPc");

    const formData = new FormData();
    formData.append("file", selectedFile);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    fetch(apiUrl, requestOptions)
      .then(response => response.json())  // Assuming the server responds with JSON
      .then(result => {
        const resultDetail = result.detail ? result.detail : 'Upload failed. Please try again.';  // Default error message
        setMessage(resultDetail);

        // If the message contains 'failed', consider it a failure
        if (resultDetail.toLowerCase().includes("failed") || resultDetail === "No Instructor matches the given query." || resultDetail === "No file uploaded.") {
          setIsSuccess(false);  // Failure
        } else {
          setIsSuccess(true);  // Success
        }
      })
      .catch(error => {
        setMessage(` ${error.message}`);
        setIsSuccess(false);  // Failure
      });
  };

  return (
    <div className={styles.container}>
      <span className={styles.note}>
        Note: Please ensure to first upload the 'Coaches' file, followed by the 'Coaching Rounds' file. Only after successfully uploading both, proceed to upload the 'Students' file. If the 'Coaches' and 'Coaching Rounds' data already exist, the 'Students' file can be uploaded, and the data will automatically be linked to the respective coaches.
        <br></br>
        <br></br>
        ملحوظه: "يرجى التأكد من تحميل ملف "المدربين" أولاً، يليه ملف "الجولات التدريبية". بعد تحميل كلا الملفين بنجاح، يمكن بعد ذلك تحميل ملف "الطلاب". إذا كانت بيانات "المدربين" و"الجولات التدريبية" موجودة مسبقاً، يمكن تحميل ملف "الطلاب" وسيتم ربط البيانات تلقائياً بالمدربين المعنيين.
        <br></br>
      </span>
      <h1 className={styles.title}>Upload CSV Files</h1>
      {message && (
        <p className={isSuccess ? styles.successMessage : styles.errorMessage}>
          {message}
        </p>
      )}
      <div className={styles.section}>
        <h2>Upload Coaches</h2>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
        <button onClick={() => uploadFile(`${API_URL}/upload/instructors/`)} className={styles.button}>Upload Coach</button>
        <a href="/instructorExample.xlsx" download className={styles.downloadLink}>Download template</a>
      </div>
      <div className={styles.section}>
        <h2>Upload Coaching Rounds</h2>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
        <button onClick={() => uploadFile(`${API_URL}/upload/coaching-rounds/`)} className={styles.button}>Upload Coaching Round</button>
        <a href="/coachingExample.xlsx" download className={styles.downloadLink}>Download template</a>
      </div>
      <div className={styles.section}>
        <h2>Upload Students</h2>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
        <button onClick={() => uploadFile(`${API_URL}/upload/students/`)} className={styles.button}>Upload Student</button>
        <a href="/studentExample.xlsx" download className={styles.downloadLink}>Download template</a>
      </div>
    </div>
  );
}

export default UploadStudents;
