import React, { useState } from 'react';
import styles from './EditStudent.module.css';
import { API_URL, getToken } from '../../Helpers/constants';

export default function EditStudent() {
    const [studentId, setStudentId] = useState('');
    const [studentData, setStudentData] = useState(null);
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false); // For PATCH request status
    const [editMode, setEditMode] = useState(false); // Toggle between view and edit mode
    const [changedFields, setChangedFields] = useState({}); // Track changed fields
    const token = getToken();

    // Fetch Student Data
    const fetchStudentData = async () => {
        if (!studentId) {
            setFormError('Student ID is required');
            return;
        }

        setLoading(true); // Set loading to true while fetching
        setFormError(''); // Clear previous errors

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "kiXydU2iTQJXw6iwh5NSeIOwlPO8O0FEc5TIPCAIqG0JT7jmTxQ0emy4BHPpts3L");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${API_URL}/accounts/student-data/${studentId}`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                setStudentData(data); // Set the fetched student data
                setFormError('');
            } else {
                setFormError('Error fetching student data');
            }
        } catch (error) {
            setFormError('An error occurred while fetching the data.');
            console.error(error);
        } finally {
            setLoading(false); // Stop loading after fetching data
        }
    };

    // Handle input changes for student data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStudentData({ ...studentData, [name]: value });
        setChangedFields((prevFields) => ({ ...prevFields, [name]: value })); // Track changed fields
        setFormError('');
        setFormSuccess('');
    };

    // Validate email before sending the update request
    const validateInput = () => {
        const { email } = studentData.user;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Valid email format

        if (changedFields.email && !emailRegex.test(email)) {
            setFormError('Email must be a valid email address');
            return false;
        }

        return true;
    };

    // Update Student Data (PATCH request)
    const updateStudentData = async () => {
        if (!validateInput()) return; // Validate email before updating

        setUpdating(true); // Set updating to true while sending the request
        setFormError('');
        setFormSuccess('');

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", "kiXydU2iTQJXw6iwh5NSeIOwlPO8O0FEc5TIPCAIqG0JT7jmTxQ0emy4BHPpts3L");

        // Prepare PATCH payload with only the changed fields
        const updatedData = {};

        if (changedFields.email || changedFields.password) {
            updatedData.user = {};
            if (changedFields.email) updatedData.user.email = changedFields.email;
            if (changedFields.password) updatedData.user.password = changedFields.password;
        }
        if (changedFields.full_name) updatedData.full_name = changedFields.full_name;
        if (changedFields.phone) updatedData.phone = changedFields.phone;
        if (changedFields.coaching_rounds) updatedData.coaching_rounds = { code: changedFields.coaching_rounds };

        // Only send changed fields using PATCH
        const requestOptions = {
            method: "PATCH", // Use PATCH instead of PUT
            headers: myHeaders,
            body: JSON.stringify(updatedData),
            redirect: "follow"
        };

        try {
            const response = await fetch(`${API_URL}/accounts/student-data/${studentId}`, requestOptions);
            if (response.ok) {
                setFormSuccess('Student data updated successfully');
                setFormError('');
                setChangedFields({}); // Clear changed fields after success
            } else {
                setFormError('Error updating student data');
            }
        } catch (error) {
            setFormError('An error occurred while updating the data.');
            console.error(error);
        } finally {
            setUpdating(false); // Stop updating after the request completes
        }
    };

    // Toggle edit mode
    const toggleEditMode = () => {
        setEditMode(!editMode);
        setFormError('');
        setFormSuccess('');
        setChangedFields({}); // Clear changed fields when toggling edit mode
    };

    return (
        <div className={styles.editStudentContainer}>
            <h2>Edit Student</h2>

            <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
                {/* Input for Student ID */}
                <label htmlFor="studentId">Student ID:</label>
                <input
                    type="text"
                    id="studentId"
                    value={studentId}
                    onChange={(e) => setStudentId(e.target.value)}
                    placeholder="Enter student ID"
                    disabled={loading || editMode} // Disable when loading or in edit mode
                />
                <button onClick={fetchStudentData} className={styles.fetchButton}>
                    {loading ? 'Loading...' : 'Fetch Student Data'}
                </button>

                {formError && <p className={styles.error}>{formError}</p>}
                {formSuccess && <p className={styles.success}>{formSuccess}</p>}

                {/* Display Student Data */}
                {studentData && (
                    <div className={styles.studentDetails}>
                        <h3>Student Details</h3>
                        <p><strong>ID:</strong> {studentData.id}</p>

                        {/* Coaching Rounds */}
                        <p><strong>Coaching Rounds:</strong> {studentData.coaching_rounds.code}</p>

                        {/* Role */}
                        <p><strong>Role:</strong> {studentData.user.is_student ? 'Student' : ''} {studentData.user.is_instructor ? 'Instructor' : ''}</p>

                        {/* Additional Information */}
                        <p><strong>Job Profile Round:</strong> {studentData.job_profile_round}</p>
                        <p><strong>Training Round:</strong> {studentData.training_round}</p>
                        <p><strong>Technical Provider:</strong> {studentData.technical_provider}</p>
                        <p><strong>Track:</strong> {studentData.track}</p>
                        <p><strong>Job Profile:</strong> {studentData.job_profile}</p>
                        
                        {/* Community Link (Read-only view) */}
                        <p><strong>Community Link:</strong> {studentData.community_link}</p>

                        {/* Toggle edit button */}
                        <button onClick={toggleEditMode} className={styles.editToggleButton}>
                            {editMode ? 'Cancel Edit' : 'Edit'}
                        </button>

                        {/* Editable Fields */}
                        <label htmlFor="full_name">Full Name:</label>
                        <input
                            type="text"
                            name="full_name"
                            id="full_name"
                            value={studentData.full_name}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />

                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={studentData.user.email}
                            onChange={(e) => {
                                setStudentData({ ...studentData, user: { ...studentData.user, email: e.target.value } });
                                setChangedFields((prevFields) => ({ ...prevFields, email: e.target.value }));
                            }}
                            disabled={!editMode}
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={studentData.user.password || ''}
                            onChange={(e) => {
                                setStudentData({ ...studentData, user: { ...studentData.user, password: e.target.value } });
                                setChangedFields((prevFields) => ({ ...prevFields, password: e.target.value }));
                            }}
                            disabled={!editMode}
                            placeholder="Leave empty to keep current password"
                        />

                        <label htmlFor="phone">Phone:</label>
                        <input
                            type="text"
                            name="phone"
                            id="phone"
                            value={studentData.phone}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />

                        {/* Show update button only in edit mode */}
                        {editMode && (
                            <button onClick={updateStudentData} className={styles.updateButton}>
                                {updating ? 'Updating...' : 'Update Student Data'}
                            </button>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
}
