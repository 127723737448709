import React, { useState } from 'react';
import styles from './EditInstructor.module.css';
import { API_URL, getToken } from '../../Helpers/constants';

export default function EditInstructor() {
    const [instructorId, setInstructorId] = useState('');
    const [instructorData, setInstructorData] = useState(null);
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const token = getToken();

    // Fetch Instructor Data
    const fetchInstructorData = async () => {
        if (!instructorId) {
            setFormError('Instructor ID is required');
            return;
        }

        setLoading(true); // Set loading to true while fetching
        setFormError(''); // Clear previous errors

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "kiXydU2iTQJXw6iwh5NSeIOwlPO8O0FEc5TIPCAIqG0JT7jmTxQ0emy4BHPpts3L");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${API_URL}/accounts/instructor-data/${instructorId}`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                setInstructorData(data); // Set the fetched instructor data
                setFormError('');
            } else {
                setFormError('Error fetching instructor data');
            }
        } catch (error) {
            setFormError('An error occurred while fetching the data.');
            console.error(error);
        } finally {
            setLoading(false); // Stop loading after fetching data
        }
    };

    // Handle input changes for instructor data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInstructorData({ ...instructorData, [name]: value });
        setFormError('');
        setFormSuccess('');
    };

    // Validate email before sending the update request
    const validateInput = () => {
        const { email } = instructorData.user;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Valid email format

        if (!emailRegex.test(email)) {
            setFormError('Email must be a valid email address');
            return false;
        }
        return true;
    };

    // Update Instructor Data (PUT request)
    const updateInstructorData = async () => {
        if (!validateInput()) return; // Validate email before updating

        setUpdating(true); // Set updating to true while sending the request
        setFormError('');
        setFormSuccess('');

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", "kiXydU2iTQJXw6iwh5NSeIOwlPO8O0FEc5TIPCAIqG0JT7jmTxQ0emy4BHPpts3L");

        const { user, name, phone } = instructorData;

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({
                user: {
                    email: user.email,
                    password: user.password || '', // Ensure password is either updated or left as empty string
                },
                name: name,
                phone: phone
            }),
            redirect: "follow"
        };

        try {
            const response = await fetch(`${API_URL}/accounts/instructor-data/${instructorId}`, requestOptions);
            if (response.ok) {
                setFormSuccess('Instructor data updated successfully');
                setFormError('');
            } else {
                setFormError('Error updating instructor data');
            }
        } catch (error) {
            setFormError('An error occurred while updating the data.');
            console.error(error);
        } finally {
            setUpdating(false); // Stop updating after the request completes
        }
    };

    // Toggle edit mode and clear only editable fields (not ID, Role, etc.)
    const toggleEditMode = () => {
        setEditMode(!editMode);
        setFormError('');
        setFormSuccess('');
        if (!editMode && instructorData) {
            // Clear only the editable fields (name, email, password, phone)
            setInstructorData({
                ...instructorData,
                name: '',
                phone: '',
                user: {
                    ...instructorData.user,
                    email: '',
                    password: '',
                },
            });
        }
    };

    return (
        <div className={styles.editInstructorContainer}>
            <h2>Edit Coach</h2>

            <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
                {/* Input for Instructor ID */}
                <label htmlFor="instructorId">Coach ID:</label>
                <input
                    type="text"
                    id="instructorId"
                    value={instructorId}
                    onChange={(e) => setInstructorId(e.target.value)}
                    placeholder="Enter coach ID"
                    disabled={loading || editMode} // Disable when loading or in edit mode
                />
                <button onClick={fetchInstructorData} className={styles.fetchButton}>
                    {loading ? 'Loading...' : 'Fetch Coach Data'}
                </button>

                {formError && <p className={styles.error}>{formError}</p>}
                {formSuccess && <p className={styles.success}>{formSuccess}</p>}

                {/* Display Instructor Data */}
                {instructorData && (
                    <div className={styles.instructorDetails}>
                        <h3>Coach Details</h3>
                        <p><strong>ID:</strong> {instructorData.id}</p>

                        <p><strong>Coaching Rounds:</strong> {instructorData.coaching_rounds?.map(round => round.code).join(', ')}</p>

                        {/* Role */}
                        <p><strong>Role:</strong> {instructorData.user?.is_student ? 'Student' : ''} {instructorData.user?.is_instructor ? 'Coach' : ''}</p>

                        {/* Toggle edit button */}
                        <button onClick={toggleEditMode} className={styles.editToggleButton}>
                            {editMode ? 'Cancel Edit' : 'Edit'}
                        </button>

                        {/* Editable Fields */}
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={instructorData.name}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />

                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={instructorData.user.email}
                            onChange={(e) => setInstructorData({ ...instructorData, user: { ...instructorData.user, email: e.target.value } })}
                            disabled={!editMode}
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={instructorData.user.password || ''}
                            onChange={(e) => setInstructorData({ ...instructorData, user: { ...instructorData.user, password: e.target.value } })}
                            disabled={!editMode}
                        />

                        <label htmlFor="phone">Phone:</label>
                        <input
                            type="text"
                            name="phone"
                            id="phone"
                            value={instructorData.phone}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />

                        {/* Show update button only in edit mode */}
                        {editMode && (
                            <button onClick={updateInstructorData} className={styles.updateButton}>
                                {updating ? 'Updating...' : 'Update Coach Data'}
                            </button>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
}
