import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import styles from './LiveTracking.module.css';
import GetALLMeetings from '../../Components/GetALLMeetings/GetALLMeetings';
import WebinarLiveTracking from '../../Components/WebinarLiveTracking/WebinarLiveTracking';
import JobsStatics from '../../Components/JobsStatics/JobsStatics';

const LiveTracking = () => {
  const [tabValue, setTabValue] = useState(0);

  // Handle tab change
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>

      <JobsStatics />

      <div className={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          className={styles.infoTabs}
          textColor='primary'
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            label='Webinars'
            className={`${styles.navLink} ${tabValue === 0 ? styles.activeTab : ''
              }`}
          />
          <Tab
            label='Coaching sessions'
            className={`${styles.navLink} ${tabValue === 1 ? styles.activeTab : ''
              }`}
          />
        </Tabs>
      </div>

      {/* Show different data for each tab */}
      {tabValue === 0 && <WebinarLiveTracking />}
      {tabValue === 1 && <GetALLMeetings />}
    </div>
  );
};

export default LiveTracking;
