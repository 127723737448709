import React, { useState, useEffect } from 'react';
import styles from './MeetingList.module.css'; // Assuming the same styles as WebinarList
import { API_URL, getToken } from '../../Helpers/constants';

function MeetingList() {
    const [meetings, setMeetings] = useState([]);

    useEffect(() => {
        const fetchMeetings = async () => {
            const myHeaders = new Headers();
            myHeaders.append('accept', 'application/json');
            myHeaders.append('Authorization', `Bearer ${getToken()}`);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            try {
                const response = await fetch(`${API_URL}/meetings/`, requestOptions);
                const result = await response.json();
                setMeetings(result);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchMeetings();
    }, []);

    return (
        <div className={styles.meetingListContainer}>
            <div className={styles.meetingCards}>
                {meetings.length === 0 ? (
                    <p>No coaching sessions available.</p>
                ) : (
                    meetings.map((meeting) => (
                        <div key={meeting.id} className={styles.meetingCard}>
                            <h3 className={styles.meetingName}>{meeting.title}</h3>
                            <p className={styles.meetingInstructor}><strong>Coach:</strong> {meeting.instructor}</p>
                            <p className={styles.meetingDate}><strong>Date:</strong> {new Date(meeting.date).toLocaleDateString()}</p>
                            <p className={styles.meetingTime}><strong>Start Time:</strong> {meeting.start_time}</p>
                            <p className={styles.meetingTime}><strong>End Time:</strong> {meeting.end_time}</p>
                            <p className={styles.meetingReserved}>
                                <strong>Reserved:</strong> {meeting.is_reserved ? "Yes" : "No"}
                            </p>

                            <a
                                href={meeting.meeting_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.meetingLink}
                            >
                                coaching session Link
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default MeetingList;
