import React, { useState } from 'react';
import Modal from '../../Components/Modal/Modal'; // Modal component
import MeetingList from '../../Components/MeetingList/MeetingList'; // Existing MeetingList component
import styles from './AddMeeting.module.css'; // Styles for AddMeeting
import { API_URL, getToken } from '../../Helpers/constants';

function AddMeeting() {
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedFile, setSelectedFile] = useState(null); // File state
  const [message, setMessage] = useState(''); // Message state for success/error feedback
  const [isSuccess, setIsSuccess] = useState(false);
  const token = getToken(); // Get the token for authorization

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Set selected file
    setMessage(''); // Clear any existing message
    setIsSuccess(false); // Reset success/error state
  };

  const handleUploadFile = () => {
    if (!selectedFile) {
      setMessage('Please select a file before uploading.');
      setIsSuccess(false); // This is an error, so not success
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('X-CSRFToken', 'l4tobGgiEJnq5digY0iEelMrb6v0d2NQ1O0UkiqBUXF0HXok6SmzLunWAcLY5qXB');

    const formData = new FormData();
    formData.append('file', selectedFile); // Append selected file to formData

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };

    fetch(`${API_URL}/upload/meetings`, requestOptions)
      .then((response) => response.json()) // Assuming server responds with JSON
      .then((result) => {
        if (result.detail) {
          setMessage(`Success: ${result.detail}`);
          setIsSuccess(true);
          //Close modal after 4 seconds
          setTimeout(() => {
            setShowModal(false);
            setMessage(''); // Clear the message after closing the modal
          }, 4000);
        } else {
          setMessage('File uploaded successfully.');
          setIsSuccess(true); // Mark as success
          // Close modal after 4 seconds
          setTimeout(() => {
            setShowModal(false);
            setMessage(''); // Clear the message after closing the modal
          }, 4000);
        }
      })
      .catch((error) => {
        setMessage(`Error: ${error.message}`);
        setIsSuccess(false); // Mark as error
      });
  };

  return (
    <div className={styles.addMeetingContainer}>
      <div className={styles.header}>
        <h1>coaching session List</h1>
        <button className={styles.uploadButton} onClick={() => setShowModal(true)}>
          Upload CSV
        </button>
      </div>

      <MeetingList />

      {/* Modal for file upload */}
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <h2 className={styles.modalUploadHeader}>Upload CSV File</h2>
        <div className={styles.uploadSection}>
          <input type="file" onChange={handleFileChange} className={styles.fileInput} />
          <div className={styles.btnSection}>
            <button onClick={handleUploadFile} className={styles.uploadButton}>
              Upload
            </button>

            {/* Download Template */}
            <a href="/meetingExample.xlsx" download className={styles.downloadLink}>
              Download Coaching Session Template
            </a>
          </div>

          {/* Display the message with success or error styles */}
          {message && (
            <p className={isSuccess ? styles.successMessage : styles.errorMessage}>
              {message}
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AddMeeting;
