import React, { useEffect, useState } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './PassedStudent.module.css';
import { Link } from 'react-router-dom';

function PassedStudent() {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = getToken();

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "wK7YB2jTa3u12aKS64ttydcuEpxgU4ihFeZBv33UyDVhyg3qsqtYK2qy5bGOT2Kz");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${API_URL}/students-passed/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setStudents(result);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching students data:", error);
                setError("Failed to fetch data");
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div className={styles.passed}>Loading Students...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (students.length === 0) {
        return <div className={styles.passed}>No passed students found.</div>;
    }

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Passed Students</h2>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Student ID</th>
                        <th>Name</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    {students.map((student) => (
                        <tr key={student.student_id}>
                            <td>{student.student_id}</td>
                            <td>{student.name}</td>
                            <td><Link to={`/student-profile/${student.student_id}`}> View Student Profile</Link></td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PassedStudent;

