import React, { useEffect, useState } from "react";
import round from "../../assets/round.svg";
import dateIcon from "../../assets/date.svg";
import timeIcon from "../../assets/time.svg";
import styles from "./Webinar.module.css";
import { API_URL, getToken } from "../../Helpers/constants";

const WebinarPage = () => {
    const [webinars, setWebinars] = useState([]);
    //eslint-disable-next-line
    const [token, setToken] = useState("");

    useEffect(() => {
        // Simulate retrieving the token dynamically
        const dynamicToken = getToken(); // Replace with your dynamic token logic
        setToken(dynamicToken);

        // Fetch webinar data when the component mounts
        fetchWebinarData(dynamicToken);
    }, []);

    const fetchWebinarData = (token) => {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append(
            "X-CSRFToken",
            "xmhB9v3mimkr2b6h9gijvOYTeo2fsmKKmzPMOPCEkGG1Y31qNZRUvjP20zd8b2qR"
        );

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${API_URL}/webinars/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setWebinars(result); // Assuming the API returns an array of webinars
            })
            .catch((error) => console.error("Error fetching webinars:", error));
    };

    const handleJoinWebinar = (webinarId, link) => {

        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("X-CSRFToken", "ldPDl69KrEB4aIGtt1FrkPnzQgNvylOOd0LNXOHaYuSQxJHj5tIzkt7768OMdNcV");
        myHeaders.append("Content-Type", "application/json"); // Add this line

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ webinar_id: webinarId }),
            redirect: "follow",
        };

        fetch(`${API_URL}/webinar/self-take-attendance/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                // Navigate to the webinar link after joining
                window.open(link, "_blank"); // Open the link in a new tab
            })
            .catch((error) => console.error("Error joining webinar:", error));
    };

    const upcomingWebinars = webinars.filter(webinar => !webinar.is_finished);
    const finishedWebinars = webinars.filter(webinar => webinar.is_finished);

    return (
        <div className={styles.webinarContainer}>
            {/* <h1 className={styles.title}>Webinars</h1> */}

            {/* Display Upcoming Webinars */}
            <h2 className={styles.title}> Webinars</h2>
            {upcomingWebinars.length > 0 ? (
                upcomingWebinars.map((webinar) => (
                    <div key={webinar.id} className={styles.webinarCard}>
                        <h2>{webinar.name}</h2>

                        <p className={styles.webinarInfo}>
                            <img src={dateIcon} alt="Date Icon" className={styles.icon} />
                            <strong>Date: </strong> {webinar.date}
                        </p>

                        <p className={styles.webinarInfo}>
                            <img src={timeIcon} alt="Time Icon" className={styles.icon} />
                            <strong>Time: </strong> {webinar.time}
                        </p>

                        <p className={styles.webinarInfo}>
                            <img src={round} alt="Round Icon" className={styles.icon} />
                            <strong>Job Profile Round: </strong> {webinar.job_profile_round}
                        </p>

                        <button
                            onClick={() => handleJoinWebinar(webinar.id, webinar.link)} // Call the join function with the webinar ID and link
                            className={styles.webinarLink}
                        >
                            Join Webinar
                        </button>
                    </div>
                ))
            ) : (
                <p>No webinars </p>
            )}

            {/* Display Finished Webinars */}
            <h2 className={styles.title}>Finished Webinars</h2>
            {finishedWebinars.length > 0 ? (
                finishedWebinars.map((webinar) => (
                    <div key={webinar.id} className={styles.webinarCard}>
                        <h2>{webinar.name}</h2>

                        <p className={styles.webinarInfo}>
                            <img src={dateIcon} alt="Date Icon" className={styles.icon} />
                            <strong>Date: </strong> {webinar.date}
                        </p>

                        <p className={styles.webinarInfo}>
                            <img src={timeIcon} alt="Time Icon" className={styles.icon} />
                            <strong>Time: </strong> {webinar.time}
                        </p>

                        <p className={styles.webinarInfo}>
                            <img src={round} alt="Round Icon" className={styles.icon} />
                            <strong>Job Profile Round: </strong> {webinar.job_profile_round}
                        </p>

                        <a
                            href={webinar.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.webinarLink}
                        >
                            See Recorded Video
                        </a>
                    </div>
                ))
            ) : (
                <p>No finished webinars</p>
            )}
        </div>
    );
};

export default WebinarPage;
