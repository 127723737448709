import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import styles from './JobsStatics.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
// import { Link } from 'react-router-dom';

export default function JobsStatics() {
    const token = getToken();

    const [statisticsData, setStatisticsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const size = {
        width: 600,
        height: 250,
    };

    const arcLabelFormatter = (item) => `${item.value.toFixed(1)}% (${item.count || 0})`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("X-CSRFToken", "wdd2RbdBCY3pNRq0rkt8fWwZ3xPvyxvxGtkRZVwnLJpNyqyHw3o8oAILIvAB2n2z");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                const response = await fetch(`${API_URL}/statistics-data/`, requestOptions);
                const data = await response.json();
                setStatisticsData(data);

                setLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to fetch data");
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    if (loading) {
        return <div>Loading Dashboard...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!statisticsData) {
        return <div>No data available</div>;
    }

    // Prepare Chart Data
    const jobChartData = [
        { label: "Full-Time", value: statisticsData.full_time_jobs_percentage, count: statisticsData.full_time_jobs_count },
        { label: "Part-Time", value: statisticsData.part_time_jobs_percentage, count: statisticsData.part_time_jobs_count },
        { label: "Freelance", value: statisticsData.freelance_jobs_percentage, count: statisticsData.freelance_jobs_count },
    ];

    const jobChartDataTwo = [
        { label: "Full-Time", value: statisticsData.percentage_of_passed_full_time_jobs, count: statisticsData.no_of_passed_full_time_jobs },
        { label: "Part-Time", value: statisticsData.percentage_of_passed_part_time_jobs, count: statisticsData.no_of_passed_part_time_jobs },
        { label: "Freelance", value: statisticsData.percentage_of_passed_freelance_jobs, count: statisticsData.no_of_passed_freelance_jobs },
    ];

    const joinedData = [
        { label: "Active", value: statisticsData.joined_percentage, count: statisticsData.joined_count },
        { label: "Not Active", value: statisticsData.not_joined_percentage, count: statisticsData.not_joined_count },
    ];

    const meetingRatingData = [
        { label: "Rating 1", value: statisticsData.rating_1_percentage || 0, count: statisticsData.rating_1_count },
        { label: "Rating 2", value: statisticsData.rating_2_percentage || 0, count: statisticsData.rating_2_count },
        { label: "Rating 3", value: statisticsData.rating_3_percentage || 0, count: statisticsData.rating_3_count },
        { label: "Rating 4", value: statisticsData.rating_4_percentage || 0, count: statisticsData.rating_4_count },
        { label: "Rating 5", value: statisticsData.rating_5_percentage || 0, count: statisticsData.rating_5_count },
    ];

    const webinarData = [
        { label: "Joined", value: statisticsData.webinar_joined_percentage },
        { label: "Not Joined", value: statisticsData.webinar_not_joined_percentage },
    ];

    const isMeetingRatingsZero = meetingRatingData.every(data => data.value === 0);
    const isWebinarZero = webinarData.every(data => data.value === 0);
    const timestampDate = new Date(statisticsData.timestamp);

    const cairoOffset = 0;

    const cairoTime = new Date(timestampDate.getTime() + cairoOffset * 60 * 60 * 1000);

    const formattedDate = cairoTime.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC'
    });


    return (
        <div className={styles.dashboard}>
            <h1 className={styles.title}>
                Overview
            </h1>
            <p className={styles.lastUpdated}> Last Update : {formattedDate}</p>


            {/* Cards Section */}
            <div className={styles.cardsContainer}>
                <div className={styles.card}>
                    <h3>Total Students</h3>
                    <p className={styles.count}>{statisticsData.total_students}</p>
                </div>

                <div className={styles.card}>
                    <h3>Total Jobs</h3>
                    <p className={styles.count}>{statisticsData.total_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3>In Progress Jobs</h3>
                    <p className={styles.count}>{statisticsData.total_in_progress_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3>Completed Jobs</h3>
                    <p className={styles.count}>{statisticsData.total_passed_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3>Approved Jobs</h3>
                    <p className={styles.count}>{statisticsData.total_approved_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3>Task Submissions</h3>
                    <p className={styles.count}>{statisticsData.task_submissions}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Webinars</h3>
                    <p className={styles.count}>{statisticsData.total_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Finished Webinars</h3>
                    <p className={styles.count}>{statisticsData.finished_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Non-Finished Webinars</h3>
                    <p className={styles.count}>{statisticsData.non_finished_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total 1:1 Sessions</h3>
                    <p className={styles.count}>{statisticsData.total_meetings}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total 1:1 Reserved Sessions</h3>
                    <p className={styles.count}>{statisticsData.total_reserved_meetings}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total 1:1 Finished Sessions</h3>
                    <p className={styles.count}>{statisticsData.total_meeting_attendances}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Pending Earnings</h3>
                    <p className={styles.count}>{statisticsData.total_pending_earnings} $</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Approved Earnings</h3>
                    <p className={styles.count}>{statisticsData.total_approved_earnings} $</p>
                </div>


            </div>

            {/* Charts Section */}
            <div className={styles.chartsContainer}>
                {/* Total Jobs by Category */}
                <div className={styles.chartItem}>
                    <h2>Total Jobs (by category)</h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[
                                {
                                    data: jobChartData,
                                    arcLabel: arcLabelFormatter,
                                    arcLabelMinAngle: 35,
                                    arcLabelRadius: '60%',
                                },
                            ]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>

                {/* Passed Jobs by Category */}
                <div className={styles.chartItem}>
                    <h2>Completed Jobs (by category)</h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[
                                {
                                    data: jobChartDataTwo,
                                    arcLabel: arcLabelFormatter,
                                    arcLabelMinAngle: 35,
                                    arcLabelRadius: '60%',
                                },
                            ]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>

                {/* Active Students */}
                <div className={styles.chartItem}>
                    <h2>Active Students</h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[
                                {
                                    data: joinedData,
                                    arcLabel: arcLabelFormatter,
                                    arcLabelMinAngle: 35,
                                    arcLabelRadius: '60%',
                                },
                            ]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>

                {/* Webinar Status */}
                <div className={styles.chartItem}>
                    <h2>Webinar Status</h2>
                    {isWebinarZero ? (
                        <div>No webinar data available (0%)</div>
                    ) : (
                        <div className={styles.chartComponent}>
                            <PieChart
                                series={[
                                    {
                                        data: webinarData,
                                        arcLabel: arcLabelFormatter,
                                        arcLabelMinAngle: 35,
                                        arcLabelRadius: '60%',
                                    },
                                ]}
                                sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                                {...size}
                            />
                        </div>
                    )}
                </div>

                {/* Meeting Ratings */}
                <div className={styles.chartItem}>
                    <h2>Meeting Ratings</h2>
                    {isMeetingRatingsZero ? (
                        <div>No ratings data available (0%)</div>
                    ) : (
                        <div className={styles.chartComponent}>
                            <PieChart
                                series={[
                                    {
                                        data: meetingRatingData,
                                        arcLabel: arcLabelFormatter,
                                        arcLabelMinAngle: 35,
                                        arcLabelRadius: '60%',
                                    },
                                ]}
                                sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                                {...size}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
