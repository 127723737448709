import React, { useEffect, useState } from 'react';
import styles from './CoachProfile.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
import { useParams } from 'react-router-dom';

function CoachProfile() {
  const [coachData, setCoachData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = getToken();
  const { id } = useParams();


  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "o7MOjdwi4ZjmsRUQFIkGqFVX9FhTLGi3xvOMgC3eCAFs5Dzp24aPNP4SZtz1oGxR");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${API_URL}/admin/instructors/${id}/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCoachData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching the data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading data.</p>;
  }

  return (
    <>
      <h2 className={styles.title}>Coach Profile</h2>

      <div className={styles.profileContainer}>

        <div className={styles.studentInfo}>
          <div className={styles.infoCard}>
            <p><strong>Name:</strong> {coachData.name || "N/A"}</p>
          </div>
          <div className={styles.infoCard}>
            <p><strong>Phone:</strong> {coachData.phone || "N/A"}</p>
          </div>
          <div className={styles.infoCard}>
            <p><strong>Total Meetings:</strong> {coachData.total_meetings || "N/A"}</p>
          </div>
          <div className={styles.infoCard}>
            <p><strong>Free Slots:</strong> {coachData.free_slots || "N/A"}</p>
          </div>

          <div className={styles.infoCard}>
            <p><strong>Average Rating:</strong> {coachData.average_rating || "N/A"}</p>
          </div>
        </div>
      </div>
      <h2 className={styles.feedbackWork}>Feedbacks:</h2>
      <div className={styles.feedbackCards}>
        {coachData.all_meeting_feedbacks.map((feedback, index) => (
          <div key={index} className={styles.feedbackCard}>
            <h3>Meeting {feedback.meeting}</h3>
            <p><strong>Student Name:</strong> {feedback.student_name}</p>
            <p><strong>Instructor Name:</strong> {feedback.instructor_name}</p>
            <p><strong>Rating:</strong> {feedback.rating} / 5</p>
            <p><strong>Feedback:</strong> {feedback.feedback}</p>
          </div>
        ))}
      </div>
    </>
  );
}

export default CoachProfile;
